import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import BigNumber from 'bignumber.js';
import { InfoRow } from '@copper/components';
import { DepositAddressDetails } from '@copper/components/OrderPopup/orders/LiteSettlement/DepositAddressDetails';
import { FiatSettlementInfo } from '@copper/components/OrderPopup/orders/LiteSettlement/FlowStepsList/FiatSettlementInfo';
import { selectCounterpartiesEntities, selectCounterpartiesEntitiesByOrgIds } from '@copper/entities/counterparties/counterparties-selector';
import { fetchWallets } from '@copper/entities/wallets/wallets-actions';
import { getQuoteAmount } from '@copper/helpers';
import { useCurrenciesInfo } from '@copper/hooks/useCurrenciesInfo';
import { Alert, StepItem } from '@copper/ui-kit';
import { formatCurrency } from '@copper/utils';
import { getCurrenciesByPurpose } from '../helpers';
import s from './Deposit.module.css';
const CounterpartyDeposit = ({ order, status, disabled, index, viewByAdmin = false, testId, isReceivingFiat, isFiatSettlement }) => {
    const { getDecimals } = useCurrenciesInfo();
    const [depositTarget, setDepositTarget] = useState();
    const [depositedAmount, setDepositedAmount] = useState('0');
    const { depositCurrency, depositMainCurrency } = getCurrenciesByPurpose(order);
    const counterpartiesEntitiesByOrgId = useSelector(selectCounterpartiesEntitiesByOrgIds);
    const counterparties = useSelector(selectCounterpartiesEntities);
    const recipientName = counterpartiesEntitiesByOrgId[order?.organizationId ?? '']?.counterpartyName;
    const depositAmount = order.orderType === 'buy' ? getQuoteAmount(order) : order.amount;
    const fromCounterparty = counterparties[(order.portfolioType === 'settlement-lite'
        ? order?.extra?.fromCounterpartyId
        : order.extra?.toCounterpartyId) ?? ''];
    const fetchDepositWallet = async () => {
        const [wallet] = await fetchWallets({
            portfolioId: order?.portfolioId,
            currency: depositCurrency,
            mainCurrency: depositMainCurrency,
            includeDetails: true
        }); // in case of lite there always should be one wallet in response
        const { available, _embedded: { depositTargets = [] } = {} } = wallet;
        const [depositTarget] = depositTargets; // and for lite one deposit targe per wallet
        setDepositedAmount(status === 'completed' ? depositAmount : (available ?? '0'));
        setDepositTarget(depositTarget);
    };
    useEffect(() => {
        if (!disabled) {
            fetchDepositWallet();
        }
    }, [disabled]);
    const leftToDeposit = new BigNumber(depositAmount).minus(depositedAmount).toFixed();
    const decimalPlaces = getDecimals(depositCurrency);
    const reservedCurrency = order.orderType === 'buy' ? order.baseCurrency : order.quoteCurrency;
    const reservedAmount = order.orderType === 'buy' ? order.amount : getQuoteAmount(order);
    const deliverAmount = order.orderType === 'buy' ? getQuoteAmount(order) : order.amount;
    const deliverCurrency = order.orderType === 'buy' ? order.quoteCurrency : order.baseCurrency;
    return (_jsx(StepItem, { testId: testId, open: status === 'processing', status: status, title: order.extra?.deliveryType === 'free-of-payment' ? undefined : `Step ${index}`, subTitle: `${viewByAdmin ? `${recipientName} processes settlement` : 'Process settlement'}`, disabled: disabled, children: isFiatSettlement && !isReceivingFiat ? (_jsx(FiatSettlementInfo, { order: order })) : (_jsxs(_Fragment, { children: [order.extra?.deliveryType === 'free-of-payment' ? (_jsxs("div", { children: ["To complete your leg of the settlement, please deliver", ' ', formatCurrency(depositAmount, { decimalPlaces }), " ", depositCurrency, " to the designated address."] })) : (_jsxs("div", { children: ["Copper has reserved", ' ', formatCurrency(reservedAmount, {
                            decimalPlaces: getDecimals(reservedCurrency)
                        }), ' ', reservedCurrency, " within ", fromCounterparty?.counterpartyName ?? 'a counterparty', ' ', "account, to process the settlement, please deliver", ' ', formatCurrency(deliverAmount, {
                            decimalPlaces: getDecimals(reservedCurrency)
                        }), ' ', deliverCurrency, " to the designated address. Once received, Copper will initiate the transfer of", ' ', formatCurrency(reservedAmount, {
                            decimalPlaces: getDecimals(reservedCurrency)
                        }), ' ', reservedCurrency, " to your designated receiving address"] })), _jsxs(InfoRow, { titleColor: "secondary", title: "You've deposited", children: [formatCurrency(depositedAmount, { decimalPlaces }), " ", depositCurrency] }), _jsxs(InfoRow, { titleColor: "secondary", title: "Amount left to deposit", children: [formatCurrency(BigNumber.maximum(['completed', 'canceled'].includes(status) ? '0' : leftToDeposit, '0').toFixed(), {
                            decimalPlaces
                        }), ' ', depositCurrency] }), _jsxs(InfoRow, { titleColor: "secondary", title: "Total to deposit", children: [formatCurrency(depositAmount, { decimalPlaces }), " ", depositCurrency] }), depositTarget && (_jsx("div", { className: s.depositDetailsContainer, children: _jsx(DepositAddressDetails, { addressDetails: depositTarget }) })), _jsx("div", { className: s.alertContainer, children: _jsx(Alert, { type: "warning", message: _jsxs(_Fragment, { children: [_jsx("div", { className: s.textContainer, children: "Make sure to send more than the required settlement amount to cover network fees." }), _jsx("div", { children: "Please note when you deposit funds they will immediately be allocated for settlement. Any funds that are presently available for funding may be used." })] }) }) })] })) }));
};
export { CounterpartyDeposit };
