import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import useStateMachine from '@cassiozen/usestatemachine';
import { useTopPanel } from '@copper/client/components/Toolbar/Panel/PanelContext';
import { createPledge } from '@copper/entities/oxygen-pledges/pledges-reducer';
import { selectPortfolios } from '@copper/entities/portfolios/portfolios-selector';
import { useRoutingContext } from '@copper/hooks';
import { getErrorData } from '@copper/utils';
import { yupResolver } from '@hookform/resolvers/yup';
import { BorrowDCTForm } from './Form';
import { Result } from './Result';
import { Summary } from './Summary';
import { validationSchema } from './helpers';
import s from './OxygenBorrowDCT.module.css';
export const OxygenBorrowDCT = () => {
    const dispatch = useDispatch();
    const { route } = useRoutingContext();
    const { setTopPanel } = useTopPanel();
    const portfolios = useSelector(selectPortfolios);
    const [submitErrorMessage, setSubmitErrorMessage] = useState('');
    const [createdPledge, setCreatedPledge] = useState();
    const form = useForm({
        mode: 'onBlur',
        resolver: yupResolver(validationSchema),
        defaultValues: {
            collaterals: [{}]
        }
    });
    const [state, send] = useStateMachine({
        initial: 'form',
        states: {
            form: {
                on: { NEXT_STEP: 'summary' }
            },
            summary: {
                on: { NEXT_STEP: 'result', BACK_STEP: 'form', RESET: 'form' }
            },
            result: { on: { RESET: 'form' } }
        }
    });
    useEffect(() => {
        if (route?.name === 'oxygen-borrow-dct') {
            send('RESET');
            const { portfolioId = '' } = route?.data || {};
            form.reset({
                portfolio: portfolios[portfolioId],
                newPledgeName: '',
                newPledgeDescription: '',
                collaterals: [{}]
            });
        }
    }, [route]);
    useEffect(() => {
        setTopPanel({
            title: 'Pledge',
            onBack: state.value === 'summary' ? () => send({ type: 'BACK_STEP' }) : undefined
        });
    }, [send, setTopPanel, state.value]);
    const handleFormSubmit = () => {
        send({ type: 'NEXT_STEP' });
    };
    const handleFormSend = async (values) => {
        const data = {
            portfolioId: values.portfolio.portfolioId,
            name: values.newPledgeName ?? '',
            description: values.newPledgeDescription,
            debtAmount: values.amount,
            collaterals: values.collaterals.map((collateral) => ({
                currency: collateral?.currency?.currency ?? '',
                amount: collateral.amount ?? '0'
            }))
        };
        try {
            const pledge = await dispatch(createPledge(data)).unwrap();
            setCreatedPledge(pledge);
        }
        catch (err) {
            setSubmitErrorMessage(getErrorData(err).message);
        }
        finally {
            send({ type: 'NEXT_STEP' });
        }
    };
    const handleDone = () => {
        form.reset();
        setSubmitErrorMessage('');
        send('RESET');
    };
    return (_jsxs("div", { className: s.container, children: [state.value === 'form' && _jsx(BorrowDCTForm, { form: form, onSubmit: handleFormSubmit }), state.value === 'summary' && (_jsx(Summary, { data: { ...form.getValues() }, isSubmitting: form.formState.isSubmitting, onNext: form.handleSubmit(handleFormSend) })), state.value === 'result' && (_jsx(Result, { errorMessage: submitErrorMessage, pledge: createdPledge, formData: { ...form.getValues() }, onDone: handleDone }))] }));
};
