import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { ButtonIcon, ButtonLink, IconClipboard, useSnackBar } from '@copper/ui-kit';
import { copy } from '@copper/utils';
import { BANK_ROWS_MAP } from './constants';
import s from './BankAccount.module.css';
export const BankAccount = ({ bankAccount, excludedFields }) => {
    const { openSnackbar } = useSnackBar();
    const filteredBankAccountFields = Object.entries(bankAccount).filter(([key]) => !excludedFields?.includes(key) && BANK_ROWS_MAP.get(key));
    const handleCopyAll = () => {
        const data = filteredBankAccountFields
            .map(([key, value]) => `${BANK_ROWS_MAP.get(key)}: ${value}`)
            .join('\n');
        copy(data, () => openSnackbar({ type: 'success', message: 'Copied' }));
    };
    return (_jsxs("div", { className: s.container, children: [_jsxs("div", { className: s.header, children: [_jsx("div", { className: s.title, children: "Deposit details" }), _jsx(ButtonLink, { strong: true, size: "s", text: "Copy all details", onClick: handleCopyAll })] }), filteredBankAccountFields.map(([field, value]) => (_jsxs("div", { className: s.item, children: [_jsx("div", { className: s.name, children: BANK_ROWS_MAP.get(field) }), _jsxs("div", { className: s.value, children: [value, _jsx(ButtonIcon, { onClick: () => copy(value, () => openSnackbar({ type: 'success', message: 'Copied' })), tooltipLabel: "Copy to clipboard", children: _jsx(IconClipboard, {}) })] })] }, `bank_acc_field_${field}`)))] }));
};
