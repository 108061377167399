import * as cryptoAddressesApi from '@copper/api/cryptoAddresses';
import * as travelRulesAPI from '@copper/api/travelRules';
export const getCryptoAddress = (cryptoAddressId) => cryptoAddressesApi.getAddress(cryptoAddressId).then(({ data }) => data);
export const fetchCryptoAddresses = (params) => cryptoAddressesApi.get(params).then(({ data }) => data.cryptoAddresses);
export const getTravelRules = (exchangeName) => travelRulesAPI.getTravelRulesByName(exchangeName).then((res) => res.data);
export const checkTravelRules = (address) => travelRulesAPI.checkTravelRules(address).then((res) => res.data);
export const generateOwnerVerificationLink = (ownerId) => cryptoAddressesApi.generateOwnerVerificationLink(ownerId);
export const getSharedOwners = (params) => cryptoAddressesApi.getSharedOwners(params).then((res) => res.data.owners);
export const getNonCustodialWallets = (params) => cryptoAddressesApi
    .getNonCustodialWallets(params)
    .then((response) => response.data.nonCustodialWallets);
