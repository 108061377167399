import { jsx as _jsx } from "react/jsx-runtime";
import { Suspense } from 'react';
import { Navigate, Outlet, createBrowserRouter } from 'react-router-dom';
import { Loans } from '@copper/client/pages/Loans';
import { ZendeskLogin } from '@copper/client/pages/Zendesk';
import { ZendeskError } from '@copper/client/pages/Zendesk/Error';
import { RequireAuth } from '@copper/components';
import { AddressBookBreadcrumb } from '@copper/components/AddressBook/AddressBookBreacrumb';
import { CounterpartyBreadcrumb } from '@copper/components/Counterparty/CounterpartyBreadcrumb';
import { SkeletonLoader as DashboardSkeleton } from '@copper/components/Dashboard/SkeletonLoader';
import { PortfolioBreadcrumb } from '@copper/components/Portfolio/PortfolioBreadcrumb';
import { PortfoliosChainBreadcrumb } from '@copper/components/Portfolio/PortfoliosChainBreadcrumb';
import { ActiveSessions } from '@copper/components/Settings/SecuritySettings/ActiveSessions';
import { PasswordAndPasskeys } from '@copper/components/Settings/SecuritySettings/PasswordAndPasskeys';
import { TeamMemberBreadcrumb } from '@copper/components/TeamMembers/TeamMemberBreadcrumb';
import { TeamMembersBreadcrumb } from '@copper/components/TeamMembers/TeamMembersBreadcrumb';
import { WebhookEntityBreadcrumb } from '@copper/components/Webhooks';
import { lazyWithRetry } from '@copper/helpers';
import { Loader } from '@copper/ui-kit';
import { MaintanancePlaceholder } from '@copper/components/MaintenancePlaceholder';
import { App } from './App';
import { ErrorBoundary } from './components/ErrorBoundary/ErrorBoundary';
import { Layout } from './components/Layout';
import { NotFound } from './components/NotFound/NotFound';
import { SkeletonLoader as GenericSkeletonPage } from './components/SkeletonLoader';
import { SkeletonLoader as ClearloopRiskSkeleton } from './pages/ClearloopRisk/SkeletonLoader';
import { SkeletonLoader as NetworkSkeleton } from './pages/Network/SkeletonLoader';
import { SkeletonLoader as MySettingsSkeleton } from './pages/PersonalSettings/SkeletonLoader';
export const router = createBrowserRouter([
    {
        path: '/',
        element: (_jsx(ErrorBoundary, { children: _jsx(Suspense, { fallback: _jsx("div", { style: {
                        display: 'flex',
                        alignItems: 'center',
                        minHeight: '100vh'
                    }, children: _jsx(Loader, { size: "l" }) }), children: _jsx(App, {}) }) })),
        children: [
            {
                path: '/login/*',
                async lazy() {
                    const AuthPage = lazyWithRetry(() => import(/* webpackChunkName: "auth" */ './pages/Auth').then((module) => ({
                        default: module.AuthPage
                    })));
                    return { Component: AuthPage };
                }
            },
            {
                path: '/404',
                element: _jsx(NotFound, {})
            },
            {
                path: '/scheduled-maintenance',
                element: _jsx(MaintanancePlaceholder, {})
            },
            {
                path: 'zendesk',
                children: [
                    {
                        index: true,
                        element: _jsx(Navigate, { to: "login", replace: true })
                    },
                    {
                        path: 'login',
                        element: (_jsx(RequireAuth, { children: _jsx(ZendeskLogin, {}) }))
                    },
                    {
                        path: 'logout',
                        element: _jsx(ZendeskError, {})
                    }
                ]
            },
            {
                path: '*',
                element: (_jsx(RequireAuth, { children: _jsx(Layout, {}) })),
                children: [
                    {
                        index: true,
                        element: _jsx(Navigate, { to: "/accounts/all", replace: true })
                    },
                    {
                        path: 'reports',
                        handle: {
                            crumb: 'All accounts'
                        },
                        children: [
                            {
                                path: '*',
                                index: true,
                                element: _jsx(Navigate, { to: "all", replace: true })
                            }
                        ]
                    },
                    {
                        path: 'orders/:orderId',
                        async lazy() {
                            const OrderPage = lazyWithRetry(() => import(/* webpackChunkName: "order-page" */ './pages/OrderPage').then((module) => ({
                                default: module.OrderPage
                            })));
                            return { Component: OrderPage };
                        }
                    },
                    {
                        path: 'terms-and-conditions',
                        async lazy() {
                            const TermsAndConditionsPage = lazyWithRetry(() => import(
                            /* webpackChunkName: "terms-and-conditions" */ './pages/TermsAndConditions').then((module) => ({
                                default: module.TermsAndConditionsPage
                            })));
                            return { Component: TermsAndConditionsPage };
                        }
                    },
                    {
                        path: 'nctw-terms-and-conditions',
                        async lazy() {
                            const NCTWTermsAndConditionsPage = lazyWithRetry(() => import(
                            /* webpackChunkName: "nctw-terms-and-conditions" */ './pages/NCTWTermsAndConditions').then((module) => ({
                                default: module.NCTWTermsAndConditions
                            })));
                            return { Component: NCTWTermsAndConditionsPage };
                        }
                    },
                    {
                        path: 'connect-permissions',
                        async lazy() {
                            const ConnectPermissionsPage = lazyWithRetry(() => import(
                            /* webpackChunkName: "connect-permissions" */ './pages/ConnectPermissions').then((module) => ({
                                default: module.ConnectPermissionsPage
                            })));
                            return { Component: ConnectPermissionsPage };
                        }
                    },
                    {
                        path: 'network',
                        element: _jsx(Outlet, {}),
                        children: [
                            {
                                index: true,
                                element: _jsx(Navigate, { to: "counterparties", replace: true })
                            },
                            {
                                path: 'counterparties',
                                handle: {
                                    crumb: 'All participants'
                                },
                                children: [
                                    {
                                        index: true,
                                        async lazy() {
                                            const NetworkPage = lazyWithRetry(() => import(/* webpackChunkName: "network" */ './pages/Network').then((module) => ({
                                                default: module.NetworkPage
                                            })), NetworkSkeleton);
                                            return { Component: NetworkPage };
                                        }
                                    },
                                    {
                                        path: ':counterpartyId/*',
                                        handle: {
                                            crumb: (params, pathname) => (_jsx(CounterpartyBreadcrumb, { counterpartyId: params.counterpartyId, pathname: pathname }))
                                        },
                                        async lazy() {
                                            const NetworkParticipantPage = lazyWithRetry(() => import(
                                            /* webpackChunkName: "counterparty-page" */ './pages/NetworkParticipant').then((module) => ({
                                                default: module.NetworkParticipantPage
                                            })), NetworkSkeleton);
                                            return { Component: NetworkParticipantPage };
                                        }
                                    }
                                ]
                            }
                        ]
                    },
                    {
                        path: 'loans/*',
                        async lazy() {
                            return {
                                element: (_jsx(Suspense, { fallback: _jsx(GenericSkeletonPage, { tableItems: 5, hasSeparator: true, mainTabs: 3 }), children: _jsx(Loans, {}) }))
                            };
                        }
                    },
                    {
                        path: 'accounts',
                        handle: {
                            crumb: 'All accounts'
                        },
                        children: [
                            {
                                path: '*',
                                index: true,
                                element: _jsx(Navigate, { to: "all", replace: true })
                            },
                            {
                                path: ':portfolioId/:section?',
                                handle: {
                                    crumb: (params, pathname) => (_jsx(PortfoliosChainBreadcrumb, { portfolioId: params.portfolioId, pathname: pathname }))
                                },
                                async lazy() {
                                    const DashboardPage = lazyWithRetry(() => import(/* webpackChunkName: "dashboard" */ './pages/Dashboard').then((module) => ({
                                        default: module.DashboardPage
                                    })), DashboardSkeleton);
                                    return {
                                        element: (_jsx(Suspense, { fallback: _jsx(DashboardSkeleton, {}), children: _jsx(DashboardPage, {}) }))
                                    };
                                }
                            }
                        ]
                    },
                    {
                        path: 'settings',
                        element: (_jsx(Suspense, { fallback: _jsx(GenericSkeletonPage, { mainTabs: 2, hasSubtitle: false }), children: _jsx(Outlet, {}) })),
                        children: [
                            {
                                index: true,
                                element: _jsx(Navigate, { to: "organisation-information", replace: true })
                            },
                            {
                                path: 'organisation-information',
                                handle: {
                                    crumb: 'Organisation information'
                                },
                                async lazy() {
                                    const OrganisationInformation = lazyWithRetry(() => import(
                                    /* webpackChunkName: "organisation-information" */ './pages/Settings/OrganisationInformation').then((module) => ({
                                        default: module.OrganisationInformation
                                    })));
                                    return { Component: OrganisationInformation };
                                }
                            },
                            {
                                path: 'address-book/*',
                                handle: {
                                    crumb: (_params, pathname) => {
                                        return _jsx(AddressBookBreadcrumb, { section: 'address-book', pathname: pathname });
                                    }
                                },
                                async lazy() {
                                    const AddressBook = lazyWithRetry(() => import(
                                    /* webpackChunkName: "address-book" */ './pages/Settings/AddressBook').then((module) => ({
                                        default: module.AddressBookPage
                                    })));
                                    return { Component: AddressBook };
                                },
                                children: [
                                    {
                                        path: ':section/*',
                                        handle: {
                                            crumb: (params, pathname) => (_jsx(AddressBookBreadcrumb, { section: params?.section, pathname: pathname }))
                                        },
                                        element: _jsx(Outlet, {}),
                                        children: [
                                            {
                                                path: ':tab',
                                                handle: {
                                                    crumb: (params, pathname) => (_jsx(AddressBookBreadcrumb, { section: params?.tab, pathname: pathname }))
                                                },
                                                element: _jsx(Outlet, {})
                                            }
                                        ]
                                    }
                                ]
                            },
                            {
                                path: 'team-members/*',
                                handle: { crumb: 'Team members' },
                                async lazy() {
                                    const TeamMembersListPage = lazyWithRetry(() => import(
                                    /* webpackChunkName: "team-members" */ './pages/Settings/TeamMembers/TeamMembersList').then((module) => ({
                                        default: module.TeamMembersListPage
                                    })));
                                    return { Component: TeamMembersListPage };
                                },
                                children: [
                                    {
                                        path: ':section/*',
                                        handle: {
                                            crumb: (params, pathname) => (_jsx(TeamMembersBreadcrumb, { section: params?.section, pathname: pathname }))
                                        },
                                        element: _jsx(Outlet, {}),
                                        children: [
                                            {
                                                path: ':userId/*',
                                                handle: {
                                                    crumb: (params, pathname) => (_jsx(TeamMemberBreadcrumb, { userId: params?.userId, pathname: pathname }))
                                                },
                                                async lazy() {
                                                    const TeamMemberProfilePage = lazyWithRetry(() => import(
                                                    /* webpackChunkName: "team-member-page" */ './pages/Settings/TeamMembers/TeamMemberProfile').then((module) => ({
                                                        default: module.TeamMemberProfilePage
                                                    })));
                                                    return { Component: TeamMemberProfilePage };
                                                },
                                                children: [
                                                    {
                                                        path: ':profileSection',
                                                        element: _jsx(Outlet, {})
                                                    }
                                                ]
                                            }
                                        ]
                                    }
                                ]
                            },
                            {
                                path: 'service-accounts/*',
                                handle: { crumb: 'Service accounts' },
                                async lazy() {
                                    const ServiceAccountsListPage = lazyWithRetry(() => import(
                                    /* webpackChunkName: "service-accounts" */ './pages/Settings/ServiceAccounts/ServiceAccountsList').then((module) => ({
                                        default: module.ServiceAccountsListPage
                                    })));
                                    return { Component: ServiceAccountsListPage };
                                },
                                children: [
                                    {
                                        path: ':section/*',
                                        handle: {
                                            crumb: (params, pathname) => (_jsx(TeamMembersBreadcrumb, { section: params?.section, pathname: pathname }))
                                        },
                                        element: _jsx(Outlet, {}),
                                        children: [
                                            {
                                                path: ':userId/*',
                                                handle: {
                                                    crumb: (params, pathname) => (_jsx(TeamMemberBreadcrumb, { userId: params?.userId, pathname: pathname }))
                                                },
                                                async lazy() {
                                                    const TeamMemberProfilePage = lazyWithRetry(() => import(
                                                    /* webpackChunkName: "service-account-page" */ './pages/Settings/TeamMembers/TeamMemberProfile').then((module) => ({
                                                        default: module.TeamMemberProfilePage
                                                    })));
                                                    return { Component: TeamMemberProfilePage };
                                                },
                                                children: [
                                                    {
                                                        path: ':profileSection',
                                                        element: _jsx(Outlet, {})
                                                    }
                                                ]
                                            }
                                        ]
                                    }
                                ]
                            },
                            {
                                path: 'invoices',
                                handle: {
                                    crumb: 'Invoices'
                                },
                                async lazy() {
                                    const InvoicesPage = lazyWithRetry(() => import(/* webpackChunkName: "invoices" */ './pages/Settings/Invoices').then((module) => ({
                                        default: module.Invoices
                                    })));
                                    return { Component: InvoicesPage };
                                }
                            },
                            {
                                path: 'webhooks',
                                element: _jsx(Outlet, {}),
                                handle: {
                                    crumb: 'Webhooks'
                                },
                                children: [
                                    {
                                        index: true,
                                        element: _jsx(Navigate, { to: "active", replace: true })
                                    },
                                    {
                                        path: ':section',
                                        async lazy() {
                                            const WebhooksSettingPage = lazyWithRetry(() => import(/* webpackChunkName: "webhooks" */ './pages/Settings/Webhooks').then((module) => ({
                                                default: module.Webhooks
                                            })));
                                            return { Component: WebhooksSettingPage };
                                        }
                                    },
                                    {
                                        path: ':section/:subscriptionId',
                                        async lazy() {
                                            const WebhookEventsSettingPage = lazyWithRetry(() => import(
                                            /* webpackChunkName: "webhook-page" */ './pages/Settings/WebhookEvents').then((module) => ({
                                                default: module.WebhookEvents
                                            })));
                                            return { Component: WebhookEventsSettingPage };
                                        },
                                        handle: {
                                            crumb: (params, pathname) => (_jsx(WebhookEntityBreadcrumb, { subscriptionId: params.subscriptionId, pathname: pathname }))
                                        }
                                    }
                                ]
                            },
                            {
                                path: 'network',
                                async lazy() {
                                    const NetworkSettingsPage = lazyWithRetry(() => import(/* webpackChunkName: "network" */ './pages/Settings/Network').then((module) => ({
                                        default: module.Network
                                    })));
                                    return { Component: NetworkSettingsPage };
                                },
                                handle: {
                                    crumb: 'Network'
                                }
                            },
                            {
                                path: 'api-keys',
                                async lazy() {
                                    const ApiKeys = lazyWithRetry(() => import(/* webpackChunkName: "api-keys" */ './pages/Settings/ApiKeys').then((module) => ({
                                        default: module.ApiKeysPage
                                    })));
                                    return { Component: ApiKeys };
                                },
                                handle: {
                                    crumb: 'API keys'
                                },
                                children: [
                                    {
                                        path: ':section/*',
                                        element: _jsx(Outlet, {})
                                    }
                                ]
                            },
                            {
                                path: 'copper-connect',
                                async lazy() {
                                    const CopperConnect = lazyWithRetry(() => import(
                                    /* webpackChunkName: "copper-connect" */ './pages/Settings/CopperConnect').then((module) => ({
                                        default: module.CopperConnect
                                    })));
                                    return { Component: CopperConnect };
                                },
                                handle: {
                                    crumb: 'Copper connect'
                                }
                            },
                            {
                                path: 'fees/*',
                                async lazy() {
                                    const Fees = lazyWithRetry(() => import(/* webpackChunkName: "fees" */ './pages/Settings/Fees').then((module) => ({
                                        default: module.Fees
                                    })));
                                    return { Component: Fees };
                                },
                                handle: {
                                    crumb: 'Fee schedule'
                                }
                            },
                            {
                                path: 'activity',
                                async lazy() {
                                    const AccountActivity = lazyWithRetry(() => import(
                                    /* webpackChunkName: "activity" */ './pages/Settings/AccountActivity').then((module) => ({
                                        default: module.AccountActivity
                                    })));
                                    return { Component: AccountActivity };
                                },
                                handle: {
                                    crumb: 'Account activity'
                                }
                            },
                            {
                                path: 'withdrawal-password',
                                async lazy() {
                                    const WithdrawalPasswordPage = lazyWithRetry(() => import(
                                    /* webpackChunkName: "withdrawal-password" */ './pages/Settings/WithdrawalPassword').then((module) => ({
                                        default: module.WithdrawalPasswordPage
                                    })));
                                    return { Component: WithdrawalPasswordPage };
                                },
                                handle: {
                                    crumb: 'Withdrawal password'
                                }
                            },
                            {
                                path: 'archived-accounts',
                                element: _jsx(Outlet, {}),
                                handle: {
                                    crumb: 'Archived accounts'
                                },
                                children: [
                                    {
                                        index: true,
                                        async lazy() {
                                            const ArchivedPortfoliosPage = lazyWithRetry(() => import(
                                            /* webpackChunkName: "archived-accounts" */ './pages/Settings/ArchivedPortfolios/ArchivedPortfoliosPage').then((module) => ({
                                                default: module.ArchivedPortfoliosPage
                                            })));
                                            return { Component: ArchivedPortfoliosPage };
                                        }
                                    },
                                    {
                                        path: ':portfolioId/*',
                                        async lazy() {
                                            const ArchivedPortfolioPage = lazyWithRetry(() => import(
                                            /* webpackChunkName: "archived-account-page" */ './pages/Settings/ArchivedPortfolios/ArchivedPortfolioPage').then((module) => ({
                                                default: module.ArchivedPortfolioPage
                                            })));
                                            return { Component: ArchivedPortfolioPage };
                                        },
                                        handle: {
                                            crumb: (params, pathname) => (_jsx(PortfolioBreadcrumb, { portfolioId: params.portfolioId, pathname: pathname }))
                                        }
                                    }
                                ]
                            },
                            {
                                path: 'authentication-security',
                                element: _jsx(Outlet, {}),
                                handle: {
                                    crumb: 'Authentication security'
                                },
                                children: [
                                    {
                                        index: true,
                                        async lazy() {
                                            const AuthenticationSecurity = lazyWithRetry(() => import(
                                            /* webpackChunkName: "authentication-security" */ './pages/Settings/AuthenticationSecurity').then((module) => ({
                                                default: module.AuthenticationSecurity
                                            })));
                                            return { Component: AuthenticationSecurity };
                                        }
                                    },
                                    {
                                        path: 'sso-configuration',
                                        async lazy() {
                                            const SSOConfigurationPage = lazyWithRetry(() => import(
                                            /* webpackChunkName: "sso-configuration" */ './pages/Settings/AuthenticationSecurity/SSOConfiguration').then((module) => ({
                                                default: module.SSOConfiguration
                                            })));
                                            return { Component: SSOConfigurationPage };
                                        },
                                        handle: {
                                            crumb: 'Single sign-on configuration'
                                        }
                                    }
                                ]
                            },
                            {
                                path: 'archived-rules',
                                element: _jsx(Outlet, {}),
                                handle: {
                                    crumb: 'Archived rules'
                                },
                                children: [
                                    {
                                        index: true,
                                        async lazy() {
                                            const ArchivedRulesPage = lazyWithRetry(() => import(
                                            /* webpackChunkName: "archived-rules" */ './pages/Settings/ArchivedRules').then((module) => ({
                                                default: module.ArchivedRules
                                            })));
                                            return { Component: ArchivedRulesPage };
                                        }
                                    }
                                ]
                            }
                        ]
                    },
                    {
                        path: 'my',
                        element: (_jsx(Suspense, { fallback: _jsx(MySettingsSkeleton, {}), children: _jsx(Outlet, {}) })),
                        children: [
                            {
                                index: true,
                                element: _jsx(Navigate, { to: "general", replace: true })
                            },
                            {
                                path: 'general',
                                async lazy() {
                                    const MyGeneralSettingPage = lazyWithRetry(() => import(
                                    /* webpackChunkName: "personal-settings" */ './pages/PersonalSettings/General').then((module) => ({
                                        default: module.General
                                    })), MySettingsSkeleton);
                                    return { Component: MyGeneralSettingPage };
                                },
                                handle: { crumb: 'General' }
                            },
                            {
                                path: 'security',
                                element: _jsx(Outlet, {}),
                                handle: { crumb: 'Security' },
                                children: [
                                    {
                                        index: true,
                                        async lazy() {
                                            const MySecuritySettingPage = lazyWithRetry(() => import(
                                            /* webpackChunkName: "personal-security" */ './pages/PersonalSettings/Security').then((module) => ({
                                                default: module.SecurityPage
                                            })), MySettingsSkeleton);
                                            return {
                                                Component: MySecuritySettingPage
                                            };
                                        }
                                    },
                                    {
                                        path: 'auth-methods',
                                        element: _jsx(PasswordAndPasskeys, { target: "client" }),
                                        handle: { crumb: 'Password and passkeys' }
                                    },
                                    {
                                        path: 'active-sessions',
                                        element: _jsx(ActiveSessions, {}),
                                        handle: { crumb: 'Active sessions' }
                                    }
                                ]
                            },
                            {
                                path: 'notifications',
                                async lazy() {
                                    const MyNotificationsSettingPage = lazyWithRetry(() => import(
                                    /* webpackChunkName: "personal-notifications" */ './pages/PersonalSettings/Notifications').then((module) => ({
                                        default: module.Notifications
                                    })), MySettingsSkeleton);
                                    return { Component: MyNotificationsSettingPage };
                                },
                                handle: { crumb: 'Notifications' }
                            },
                            {
                                path: 'ui',
                                async lazy() {
                                    const MyUiSettingPage = lazyWithRetry(() => import('./pages/PersonalSettings/Ui').then((module) => ({
                                        default: module.UiSettingsPage
                                    })), MySettingsSkeleton);
                                    return { Component: MyUiSettingPage };
                                },
                                handle: { crumb: 'UI settings' }
                            }
                        ]
                    },
                    {
                        path: 'clearloop-risk',
                        element: (_jsx(Suspense, { fallback: _jsx(ClearloopRiskSkeleton, {}), children: _jsx(Outlet, {}) })),
                        children: [
                            {
                                index: true,
                                element: _jsx(Navigate, { to: "exchanges-status", replace: true })
                            },
                            {
                                path: 'exchanges-status',
                                async lazy() {
                                    const ExchangesStatusPage = lazyWithRetry(() => import(
                                    /* webpackChunkName: "clearloop-risk-exchanges-status" */ './pages/ClearloopRisk/ExchangesStatus').then((module) => ({
                                        default: module.ExchangesStatusPage
                                    })), ClearloopRiskSkeleton);
                                    return { Component: ExchangesStatusPage };
                                }
                            },
                            {
                                path: 'settlements',
                                async lazy() {
                                    const SettlementsPage = lazyWithRetry(() => import(
                                    /* webpackChunkName: "clearloop-risk-settlements" */ './pages/ClearloopRisk/Settlements').then((module) => ({
                                        default: module.SettlementsPage
                                    })), ClearloopRiskSkeleton);
                                    return { Component: SettlementsPage };
                                }
                            }
                        ]
                    },
                    {
                        path: 'clearloop-reserve',
                        async lazy() {
                            const ClearloopReservePage = lazyWithRetry(() => import(/* webpackChunkName: "clearloop-reserve" */ './pages/ClearloopReserve').then((module) => ({
                                default: module.ClearloopReservePage
                            })));
                            return { Component: ClearloopReservePage };
                        }
                    },
                    {
                        path: '*',
                        element: _jsx(NotFound, { redirect: true })
                    }
                ]
            }
        ]
    }
]);
