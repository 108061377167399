import BigNumber from 'bignumber.js';
import * as Yup from 'yup';
export const validationSchema = Yup.object().shape({
    portfolio: Yup.object().required('Select an account.'),
    amount: Yup.string()
        .required('Enter a loan amount.')
        .test('amount', (value = '0', { parent, createError }) => {
        const { availableBalance = '0', currency } = parent;
        return new BigNumber(availableBalance).minus(value).gte(0)
            ? true
            : createError({
                message: `Insufficient ${currency?.currency ?? ''} balance.`
            });
    }),
    counterparty: Yup.object().required('Select a borrower.'),
    currency: Yup.object().required('Select an asset.'),
    openTerm: Yup.boolean(),
    maturityDate: Yup.string().when('openTerm', {
        is: (openTerm) => !openTerm,
        then: (schema) => schema.required('Select a loan term.')
    }),
    collateralAmount: Yup.string().required('Enter a collateral amount.'),
    collateralMainCurrency: Yup.string().required('Select a network.'),
    interestRateBps: Yup.string().required('Enter an interest rate.'),
    rebalancePercent: Yup.string().required('Enter a rebalance rate.'),
    marginCallPercent: Yup.string().required('Enter a margin call rate.'),
    defaultPercent: Yup.string().required('Enter a default rate.')
});
