import { request } from '@copper/utils';
const normalizeLoansParams = ({ statuses, ...params }) => ({
    ...params,
    status: statuses?.join(',')
});
export const createAgencyLoan = ({ organizationId, data }) => request.post(`/organizations/${organizationId}/loans`, data);
export const getLoans = (params) => request.get(`/organizations/${params.organizationId}/loans`, {
    params: normalizeLoansParams(params)
});
export const getLoanById = (params) => request.get(`/organizations/${params.organizationId}/loans/${params.loanId}`, { params });
export const acceptLoan = (organizationId, loanId, data) => request.patch(`/organizations/${organizationId}/loans/${loanId}`, data, {
    headers: {
        'Content-Type': 'application/vnd.accept-loan+json'
    }
});
export const cancelPrincipalLoan = (organizationId, loanId) => request.patch(`/organizations/${organizationId}/loans/${loanId}`, {}, {
    headers: {
        'Content-Type': 'application/vnd.cancel-loan+json'
    }
});
export const addCollateral = (organizationId, loanId, data) => request.patch(`/organizations/${organizationId}/loans/${loanId}`, data, {
    headers: {
        'Content-Type': 'application/vnd.add-collateral+json'
    }
});
export const repayLoan = (organizationId, loanId, data) => request.patch(`/organizations/${organizationId}/loans/${loanId}`, data, {
    headers: {
        'Content-Type': 'application/vnd.repay-loan+json'
    }
});
export const acceptLoanRepaymentRequest = (organizationId, loanId, data) => request.patch(`/organizations/${organizationId}/loans/${loanId}`, data, {
    headers: {
        'Content-Type': 'application/vnd.accept-loan-repayment+json'
    }
});
export const cancelLoanRepaymentRequest = (organizationId, loanId, data) => request.patch(`/organizations/${organizationId}/loans/${loanId}`, data, {
    headers: {
        'Content-Type': 'application/vnd.cancel-loan-repayment+json'
    }
});
export const rebalanceLoan = (organizationId, loanId, data) => request.patch(`/organizations/${organizationId}/loans/${loanId}`, data, {
    headers: {
        'Content-Type': 'application/vnd.rebalance-loan+json'
    }
});
export const estimateLoanLtv = (organizationId, loanId, data) => request.patch(`/organizations/${organizationId}/loans/${loanId}`, data, {
    headers: {
        'Content-Type': 'application/vnd.estimate-loan-ltv+json'
    }
});
export const estimateLoanLeverageRatio = (organizationId, loanId, data) => request.patch(`/organizations/${organizationId}/loans/${loanId}`, data, {
    headers: {
        'Content-Type': 'application/vnd.estimate-loan-leverage-ratio+json'
    }
});
export const topUpLoan = (organizationId, loanId, data) => request.patch(`/organizations/${organizationId}/loans/${loanId}`, data, {
    headers: {
        'Content-Type': 'application/vnd.top-up-loan+json'
    }
});
export const defaultLoan = (organizationId, loanId) => request.patch(`/organizations/${organizationId}/loans/${loanId}`, {}, {
    headers: {
        'Content-Type': 'application/vnd.default-loan+json'
    }
});
export const acceptLoanTopUpAction = (organizationId, loanId, data) => request.patch(`/organizations/${organizationId}/loans/${loanId}`, data, {
    headers: {
        'Content-Type': 'application/vnd.accept-loan-top-up+json'
    }
});
export const acceptLoanReleaseCollateralAction = (organizationId, loanId, data) => request.patch(`/organizations/${organizationId}/loans/${loanId}`, data, {
    headers: {
        'Content-Type': 'application/vnd.accept-loan-rebalance+json'
    }
});
export const cancelLoanTopUpAction = (organizationId, loanId, data) => request.patch(`/organizations/${organizationId}/loans/${loanId}`, data, {
    headers: {
        'Content-Type': 'application/vnd.cancel-loan-top-up+json'
    }
});
export const cancelLoanReleaseCollateralAction = (organizationId, loanId, data) => request.patch(`/organizations/${organizationId}/loans/${loanId}`, data, {
    headers: {
        'Content-Type': 'application/vnd.cancel-loan-rebalance+json'
    }
});
