import { request } from '@copper/utils';
export const get = (searchParams) => request.get(`/crypto-addresses`, {
    params: searchParams
});
export const getAddress = (cryptoAddressId) => request.get(`/crypto-addresses/${cryptoAddressId}`);
export const createNewAddress = (newAddress, twoFaCode) => request.post('/crypto-addresses', newAddress, {
    headers: {
        'X-2FA-Code': twoFaCode
    }
});
export const getAddressOwners = (organizationId) => request.get(`/address-book/owners?organizationId=${organizationId}`);
export const getSharedOwners = (params) => request.get(`/address-book/shared-owners`, { params });
export const createAddressOwner = (data) => request.post(`/address-book/owners`, data);
export const importSharedOwner = (ownerId, organizationId) => request.patch(`/address-book/owners/${ownerId}`, {
    organizationId
}, {
    headers: {
        'Content-Type': 'application/vnd.assign-owner-to-organization+json'
    }
});
export const deleteAddressOwner = (ownerId, twoFaCode) => request.delete(`/address-book/owners/${ownerId}`, {
    headers: {
        'X-2FA-Code': twoFaCode
    }
});
export const editAddressOwner = (ownerId, data) => request.patch(`/address-book/owners/${ownerId}`, data);
export const generateOwnerVerificationLink = (ownerId) => request.patch(`/address-book/owners/${ownerId}`, {}, {
    headers: {
        'Content-Type': 'application/vnd.generate-owner-verification-link+json'
    }
});
export const verifyAddressWithVisualProof = (cryptoAddressId) => request.patch(`/crypto-addresses/${cryptoAddressId}`, {
    method: 'visual-proof'
}, {
    headers: {
        'Content-Type': 'application/vnd.process-address-ownership+json'
    }
});
export const verifyAddressWithSatoshiTest = (cryptoAddressId) => request.patch(`/crypto-addresses/${cryptoAddressId}`, {
    method: 'satoshi-test'
}, {
    headers: {
        'Content-Type': 'application/vnd.process-address-ownership+json'
    }
});
export const resetSatoshiTest = (cryptoAddressId) => request.patch(`/crypto-addresses/${cryptoAddressId}`, {}, {
    headers: {
        'Content-Type': 'application/vnd.reset-satoshi-test+json'
    }
});
export const approveVisualProofTestSingle = (cryptoAddressId) => request.patch(`/crypto-addresses/${cryptoAddressId}`, {}, {
    headers: {
        'Content-Type': 'application/vnd.verify-address-ownership+json'
    }
});
export const rejectVisualProofTestSingle = (cryptoAddressId) => request.patch(`/crypto-addresses/${cryptoAddressId}`, {}, {
    headers: {
        'Content-Type': 'application/vnd.reject-address-ownership+json'
    }
});
export const approveVisualProofTest = (ownerId, vaspId) => request.patch(`/address-book/owners/${ownerId}`, {
    vaspId
}, {
    headers: {
        'Content-Type': 'application/vnd.approve-owner-vasp-verification+json'
    }
});
export const rejectVisualProofTest = (ownerId, vaspId) => request.patch(`/address-book/owners/${ownerId}`, {
    vaspId
}, {
    headers: {
        'Content-Type': 'application/vnd.reject-owner-vasp-verification+json'
    }
});
export const approveComplianceCheck = (address) => request.patch(`/investigations/addresses/${address}`, {}, {
    headers: {
        'Content-Type': 'application/vnd.approve-address-investigation+json'
    }
});
export const rejectComplianceCheck = (address) => request.patch(`/investigations/addresses/${address}`, {}, {
    headers: {
        'Content-Type': 'application/vnd.reject-address-investigation+json'
    }
});
export const editAddress = (updatedAddress, twoFaCode) => request.patch(`/crypto-addresses/${updatedAddress.cryptoAddressId}`, updatedAddress, {
    headers: {
        'X-2FA-Code': twoFaCode,
        'Content-Type': 'application/vnd.update-crypto-address-settings+json'
    }
});
export const updateTravelRules = (data) => request.patch(`/crypto-addresses/${data.cryptoAddressId}`, data, {
    headers: {
        'Content-Type': 'application/vnd.update-crypto-address-travel-rule+json'
    }
});
export const approveAddress = (cryptoAddressId, twoFaCode) => request.patch(`/crypto-addresses/${cryptoAddressId}`, {}, {
    headers: {
        'X-2FA-Code': twoFaCode,
        'Content-Type': 'application/vnd.approve-crypto-address+json'
    }
});
export const rejectAddress = (cryptoAddressId, twoFaCode) => request.patch(`/crypto-addresses/${cryptoAddressId}`, {}, {
    headers: {
        'X-2FA-Code': twoFaCode,
        'Content-Type': 'application/vnd.decline-crypto-address+json'
    }
});
export const deleteAddress = (cryptoAddressId, twoFaCode) => request.delete(`/crypto-addresses/${cryptoAddressId}`, {
    headers: {
        'X-2FA-Code': twoFaCode
    }
});
export const getNonCustodialWallets = (params) => request.get('/non-custodial-wallets', { params });
export const addNonCustodialWallet = (cryptoAddressId, data) => request.patch(`/crypto-addresses/${cryptoAddressId}`, data, {
    headers: {
        'Content-Type': 'application/vnd.add-non-custodial-wallet+json'
    }
});
export const renameNonCustodialWallet = (cryptoAddressId, data) => request.patch(`/crypto-addresses/${cryptoAddressId}`, data, {
    headers: {
        'Content-Type': 'application/vnd.rename-non-custodial-wallet+json'
    }
});
export const requestNonCustodialWalletPrivateKey = (cryptoAddressId, data) => request.patch(`/crypto-addresses/${cryptoAddressId}`, data, {
    headers: {
        'Content-Type': 'application/vnd.request-non-custodial-wallet-private-key+json'
    }
});
export const removeNonCustodialWallet = (cryptoAddressId, data) => request.patch(`/crypto-addresses/${cryptoAddressId}`, data, {
    headers: {
        'Content-Type': 'application/vnd.remove-non-custodial-wallet+json'
    }
});
