import { checkIsOwnerVerified } from '@copper/components/AddressBook/Owners/helpers';
export const getNumberOfChanges = (address) => {
    let numberOfChanges = 0;
    if (!address.extra?.isEdited)
        return numberOfChanges;
    if (!address.pendingApproval)
        return numberOfChanges;
    const { extra } = address;
    const previousPortfolioIds = extra?.previousPortfolioIds || [];
    if (!extra)
        return numberOfChanges;
    if (extra.previousAcceptTokens !== address.acceptTokens ||
        (extra.previousAcceptTokens === undefined && address.acceptTokens === false))
        numberOfChanges++;
    if (extra.previousName !== address.name)
        numberOfChanges++;
    if (extra.previousAddressType !== address.addressType)
        numberOfChanges++;
    if (extra.previousIsWhitelist !== address.isWhitelist)
        numberOfChanges++;
    if (extra.previousPortfolioIds && address.portfolioIds) {
        if (address.portfolioIds?.filter((id) => !previousPortfolioIds.includes(id)).length > 0)
            numberOfChanges++;
    }
    else if (extra.previousPortfolioIds || address.portfolioIds) {
        if (!extra.previousPortfolioIds && !!address.portfolioIds)
            numberOfChanges++;
        if (extra.previousPortfolioIds && !address.portfolioIds)
            numberOfChanges++;
    }
    return numberOfChanges;
};
export const getNumberOfChangesLabel = (address) => {
    const numberOfChanges = getNumberOfChanges(address);
    if (numberOfChanges === 0 || !address.pendingApproval) {
        return;
    }
    return `${numberOfChanges} ${numberOfChanges > 1 ? 'changes' : 'change'}`;
};
export const getIsNewAddress = (address) => !!address.pendingApproval && getNumberOfChanges(address) === 0;
export const isSmartContractAddress = (address) => ['smart-contract', 'payable-smart-contract'].includes(address.addressType);
export const isSharedOwner = (owner) => owner.beneficiaryType === 'legal-person' && checkIsOwnerVerified(owner);
export const isAddressVerified = (address) => !address._cryptoAddressEmbedded?.ownership?.ownershipStatus?.filter((ownership) => !['verified', 'not-required'].includes(ownership.aggregatedStatus || '')).length;
