import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useMemo, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import cn from 'classnames';
import { Pagination } from '@copper/components';
import { SkeletonLoader } from '@copper/components/Loans';
import { Filters } from '@copper/components/Loans/Filters';
import { ActiveLoanList } from '@copper/components/Loans/LoanList/ActiveLoanList';
import { HistoricLoanList } from '@copper/components/Loans/LoanList/HistoricLoanList';
import { getLoans } from '@copper/components/Loans/helpers';
import { ITEMS_PER_PAGE_VALUES } from '@copper/constants/pagination';
import { selectLoans, selectLoansAssets, selectLoansBorrowers, selectLoansLenders } from '@copper/entities/loans/loans-selector';
import { selectCurrentOrganization } from '@copper/entities/organizations/organizations-selector';
import { ButtonIcon, Collapse, IconFilter, Tabs } from '@copper/ui-kit';
import s from './LoanList.module.css';
const LoanList = ({ group = 'lending', isLoading, view, onLoanSelected }) => {
    const currentOrganization = useSelector(selectCurrentOrganization);
    const loansCollection = useSelector(selectLoans);
    const borrowers = useSelector(selectLoansBorrowers);
    const lenders = useSelector(selectLoansLenders);
    const loansAssets = useSelector(selectLoansAssets);
    const [activeTab, setActiveTab] = useState('Active');
    const [itemsPerPage, setItemsPerPage] = useState(20);
    const [offset, setOffset] = useState(0);
    const [filters, setFilters] = useState();
    const isBorrowingTab = group === 'borrowing';
    const sortByUpdatedAtOrCreatedAt = (loanA, loanB) => Number(loanB.updatedAt || loanB.createdAt) - Number(loanA.updatedAt || loanA.createdAt);
    const getLoansToDisplay = (loans, itemsPerPage, offset) => loans.length >= offset ? loans.slice(offset, itemsPerPage + offset) : loans;
    const handleItemsPerPageChange = (itemsSize) => {
        setOffset(0);
        setItemsPerPage(itemsSize);
    };
    const filterLoan = (loan) => {
        if (filters?.assets || filters?.parties || filters?.outcomes) {
            return ((filters.assets?.includes(loan.terms.collateralCurrency) ||
                filters.assets?.includes(loan.terms.loanCurrency) ||
                filters.assets?.length === 0) &&
                (filters.parties?.includes(loan.borrowerOrganizationId) ||
                    filters.parties?.includes(loan.lenderOrganizationId) ||
                    filters.parties?.length === 0) &&
                (filters.outcomes?.includes(loan.closedReason) || filters.outcomes?.length === 0));
        }
        return true;
    };
    const controlRef = useRef(null);
    const loansForTab = useMemo(() => getLoans({
        group,
        loans: loansCollection,
        organizationId: currentOrganization.organizationId,
        tab: activeTab
    }), [group, loansCollection, currentOrganization.organizationId, activeTab]);
    const loansToDisplay = useMemo(() => getLoansToDisplay(loansForTab.filter(filterLoan).sort(sortByUpdatedAtOrCreatedAt), itemsPerPage, offset), [loansForTab, filters, itemsPerPage, offset]);
    const scrollIntoViewWithOffset = () => {
        if (controlRef.current) {
            window.scrollTo({
                behavior: 'smooth',
                top: controlRef.current.getBoundingClientRect().top -
                    document.body.getBoundingClientRect().top -
                    200
            });
        }
    };
    const setNextPage = () => {
        scrollIntoViewWithOffset();
        if (loansForTab.length > itemsPerPage) {
            setOffset(offset + itemsPerPage);
        }
    };
    const setPrevPage = () => {
        scrollIntoViewWithOffset();
        if (offset >= itemsPerPage) {
            setOffset(offset - itemsPerPage);
        }
    };
    const handleTabClick = (item) => {
        setActiveTab(item.value);
    };
    const handleApplyFilters = (newFilters) => {
        setFilters(newFilters);
    };
    const ListComponent = group !== 'requests' && activeTab === 'History' ? HistoricLoanList : ActiveLoanList;
    return (_jsxs(_Fragment, { children: [isLoading && _jsx(SkeletonLoader, { items: 5 }), !isLoading && (_jsxs(_Fragment, { children: [_jsx("div", { className: s.viewControls, ref: controlRef, children: group !== 'requests' && (_jsx(Tabs, { items: [
                                {
                                    title: 'Active',
                                    value: 'Active'
                                },
                                {
                                    title: 'History',
                                    value: 'History'
                                }
                            ], value: activeTab, onChange: handleTabClick })) }), _jsxs("div", { className: cn({ [s.tableContainer]: group !== 'requests' }), children: [group !== 'requests' && (_jsx(Collapse, { renderToggle: (isOpen, onToggleCollapse) => (_jsx("div", { className: s.viewControlsRight, children: _jsx(ButtonIcon, { onClick: onToggleCollapse, state: !isOpen ? 'default' : 'active', children: _jsx(IconFilter, {}) }) })), children: _jsx(Filters, { handleApply: (filters) => handleApplyFilters(filters), loanParties: isBorrowingTab ? lenders : borrowers, loanAssets: loansAssets || [], group: group, tab: activeTab }) })), _jsx(ListComponent, { loans: loansToDisplay, group: group, onLoanSelected: onLoanSelected, view: view }), _jsx(Pagination, { hasMorePages: loansForTab.length > offset + itemsPerPage, isFirstPage: offset < itemsPerPage, itemsPerPage: itemsPerPage, values: ITEMS_PER_PAGE_VALUES, onChange: handleItemsPerPageChange, onNext: setNextPage, onPrev: setPrevPage })] })] }))] }));
};
export { LoanList };
