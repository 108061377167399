import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { useSelector } from 'react-redux';
import { InfoList } from '@copper/components';
import { SelectAccount } from '@copper/components/Loans/LoanModalContent/LoanManagementAction/SelectAccount';
import { SignTransfer } from '@copper/components/Loans/LoanModalContent/LoanManagementAction/SignTransfer';
import { TopUpAccount } from '@copper/components/Loans/LoanModalContent/LoanManagementAction/TopUpAccount';
import { getLoanStatusAsObject, hasAccountSelectionActions, hasFundsActions, hasSignTransactionActions, isBorrowerOrganization } from '@copper/components/Loans/helpers';
import { useCheckPermissionsContext } from '@copper/contexts/checkPermissions';
import { selectCurrentOrganization } from '@copper/entities/organizations/organizations-selector';
import { Alert } from '@copper/ui-kit';
import s from './LoanManagementAction.module.css';
const LoanManagementActions = ({ loan }) => {
    const organization = useSelector(selectCurrentOrganization);
    const { checkPermissions } = useCheckPermissionsContext();
    const { loanStatus, closedReason, pendingUserActions, terms } = loan;
    const isBorrower = isBorrowerOrganization(loan, organization);
    const { alertType, borrowerMessage, lenderMessage } = getLoanStatusAsObject({
        loanStatus: loanStatus,
        closedReason: closedReason,
        actions: pendingUserActions || [],
        isBorrower,
        terms
    });
    const showAccountSelection = !loan.loanArranger &&
        checkPermissions(['manage-loan']) &&
        hasAccountSelectionActions(pendingUserActions || [], isBorrower);
    const showSignTransfer = hasSignTransactionActions(pendingUserActions || [], isBorrower);
    const showTopUpAccount = hasFundsActions(pendingUserActions || [], isBorrower);
    const alertMessage = isBorrower ? borrowerMessage : lenderMessage;
    const showNextSteps = showAccountSelection || showSignTransfer || showTopUpAccount;
    return (_jsxs("div", { children: [showNextSteps && (_jsxs("div", { children: [showAccountSelection && (_jsx(InfoList, { className: s.loanDataList, title: "Next steps", children: _jsx(SelectAccount, { loan: loan }) })), showSignTransfer && (_jsx(InfoList, { className: s.loanDataList, title: "Next steps", children: _jsx(SignTransfer, { loan: loan }) })), showTopUpAccount && (_jsx(InfoList, { className: s.loanDataList, title: "Next steps", children: _jsx(TopUpAccount, { loan: loan }) }))] })), !showNextSteps && alertMessage && alertType && (_jsx(Alert, { noBottomMargin: true, type: alertType, message: alertMessage }))] }));
};
export { LoanManagementActions };
