import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import cn from 'classnames';
import React, { useState } from 'react';
import { Clearloop } from '@copper/client/components/Clearloop';
import { Deposit } from '@copper/client/components/Deposit';
import { Generate } from '@copper/client/components/Generate';
import { Lend } from '@copper/client/components/Lend';
import { OxygenBorrowDCT } from '@copper/client/components/OxygenBorrowDCT';
import { OxygenDeposit } from '@copper/client/components/OxygenDeposit';
import { OxygenWithdraw } from '@copper/client/components/OxygenWithdraw';
import { Settle } from '@copper/client/components/Settle';
import { Stake } from '@copper/client/components/Stake';
import { PanelContext } from '@copper/client/components/Toolbar/Panel/PanelContext';
import { Transfer } from '@copper/client/components/Transfer';
import { ButtonIcon, ButtonLink, IconArrowBack, IconClose } from '@copper/ui-kit';
import s from './Panel.module.css';
export const Panel = ({ currentView, onCloseClick }) => {
    const [topPanel, setTopPanel] = useState();
    const { title = '', subTitle = '', showGuideText, showGuide, onBack, onCloseClickOverride } = topPanel || {};
    return (_jsx(PanelContext.Provider, { value: { topPanel, setTopPanel }, children: _jsxs("div", { className: s.panel, children: [_jsxs("div", { className: s.top, children: [_jsxs("div", { className: s.topLeft, children: [onBack && (_jsx(ButtonIcon, { className: s.backButton, onClick: () => onBack(), children: _jsx(IconArrowBack, {}) })), _jsxs("div", { children: [_jsx("h5", { className: cn(s.title, { [s.titleLarge]: !subTitle }), children: title }), subTitle && _jsx("div", { className: s.subTitle, children: subTitle })] })] }), _jsxs("div", { className: s.topRight, children: [showGuide && showGuideText && (_jsx(ButtonLink, { className: s.guideLink, text: showGuideText, onClick: () => showGuide() })), _jsx("div", { className: s.closeButton, children: _jsx(ButtonIcon, { onClick: () => (onCloseClickOverride ? onCloseClickOverride() : onCloseClick()), tooltipLabel: "Close", children: _jsx(IconClose, {}) }) })] })] }), _jsxs("div", { className: s.content, children: [currentView === 'transfer' && _jsx(Transfer, {}), currentView === 'oxygen-withdraw' && _jsx(OxygenWithdraw, {}), currentView === 'oxygen-deposit' && _jsx(OxygenDeposit, {}), currentView === 'oxygen-borrow-dct' && _jsx(OxygenBorrowDCT, {}), currentView === 'clearloop' && _jsx(Clearloop, {}), currentView === 'stake' && _jsx(Stake, {}), currentView === 'settle' && _jsx(Settle, {}), currentView === 'deposit' && _jsx(Deposit, {}), currentView === 'generate' && _jsx(Generate, {}), currentView === 'lend' && _jsx(Lend, {})] })] }) }));
};
