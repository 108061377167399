import BigNumber from 'bignumber.js';
import { format, formatDistance, formatDistanceToNow, isPast } from 'date-fns';
import { intersection } from 'lodash-es';
import { formatCurrency } from '@copper/utils';
export const isLoanRequest = (loan, organizationId) => loan.pendingUserActions &&
    isNeedsAttention(loan.pendingUserActions, loan.borrowerOrganizationId === organizationId);
export const isAdminRequest = (loan) => loan.pendingUserActions &&
    loan.pendingUserActions.includes('notice-of-exclusive-control-required');
const isActiveBorrowingLoan = (loan, organizationId) => loan.loanStatus !== 'closed' && loan.borrowerOrganizationId === organizationId;
const isActiveLendingLoan = (loan, organizationId) => loan.loanStatus !== 'closed' && loan.lenderOrganizationId === organizationId;
const isClosedLendingLoan = (loan, organizationId) => loan.loanStatus === 'closed' && loan.lenderOrganizationId === organizationId;
const isClosedBorrowingLoan = (loan, organizationId) => loan.loanStatus === 'closed' && loan.borrowerOrganizationId === organizationId;
export const getCounterparty = (organizationId, loan) => organizationId === loan.lenderOrganizationId
    ? loan._embedded?.borrowerOrganization?.organizationName || loan.borrowerOrganizationId
    : loan._embedded?.lenderOrganization?.organizationName || loan.lenderOrganizationId;
export const getLoans = ({ group = 'lending', loans = [], organizationId, tab = 'Active' }) => {
    if (group === 'lending' && tab === 'Active') {
        return loans.filter((loan) => isActiveLendingLoan(loan, organizationId));
    }
    else if (group === 'borrowing' && tab === 'Active') {
        return loans.filter((loan) => isActiveBorrowingLoan(loan, organizationId));
    }
    else if (group === 'lending' && tab === 'History') {
        return loans.filter((loan) => isClosedLendingLoan(loan, organizationId));
    }
    else if (group === 'borrowing' && tab === 'History') {
        return loans.filter((loan) => isClosedBorrowingLoan(loan, organizationId));
    }
    else if (group === 'requests') {
        return loans.filter((loan) => isLoanRequest(loan, organizationId));
    }
    return [];
};
export const displayTimeToMaturity = (loan) => isActiveLoan(loan) &&
    loan.terms.maturityDate &&
    formatDistanceToNow(parseInt(loan.terms.maturityDate), { addSuffix: true });
export const displayMaturity = (loan) => loan.terms.maturityDate
    ? `${formatDistance(parseInt(loan.terms.maturityDate), parseInt(loan.terms.startDate))}`
    : '';
export const displayTimeToMaturityDate = (loan) => {
    if (loan.terminatedAt) {
        return `Closed ${format(parseInt(loan.terminatedAt), `dd MMM yyyy`)}`;
    }
    if (loan.maturityDate) {
        return formatDistanceToNow(parseInt(loan.maturityDate), { addSuffix: true });
    }
    return '';
};
export const displayMaturityDate = (loan) => loan.maturityDate ? format(parseInt(loan.maturityDate), `dd MMM yyyy`) : 'Open';
export const hasReachedMaturity = (loan) => loan.terms.maturityDate ? isPast(parseInt(loan.terms.maturityDate)) : false;
export const isActiveLoan = (loan) => !['closed', 'awaiting-configuration', 'configured'].includes(loan.loanStatus);
export const getLoanClosedReasonAsObject = (reason) => {
    switch (reason) {
        case 'cancelled':
            return {
                status: 'Cancelled',
                color: 'base',
                lenderMessage: 'This loan was cancelled before funds were disbursed.',
                borrowerMessage: 'This loan was cancelled before funds were disbursed.',
                alertType: 'info'
            };
        case 'writen-off':
            return {
                status: 'Written Off',
                color: 'base',
                lenderMessage: 'This loan was written off.',
                borrowerMessage: 'This loan was written off.',
                alertType: 'info'
            };
        case 'defaulted':
            return {
                status: 'Defaulted',
                color: 'red',
                lenderMessage: 'This loan ended before its term because the borrower failed to meet the margin call.',
                borrowerMessage: 'This loan ended before its term because the borrower failed to meet the margin call.',
                alertType: 'error'
            };
        case 'repaid':
            return {
                status: 'Repaid',
                color: 'green'
            };
        default:
            return {
                status: reason || '',
                color: 'base'
            };
    }
};
const getAwaitingConfigurationActions = (details) => {
    if (details.actions.includes('assign-borrower-portfolio') && details.isBorrower) {
        return {
            status: 'Select Account',
            text: 'Please select your account.'
        };
    }
    if (details.actions.includes('assign-lender-portfolio') && !details.isBorrower) {
        return {
            status: 'Select Account',
            text: 'Please select your account.'
        };
    }
    if (details.actions.includes('collateral-asset-activation') && details.isBorrower) {
        return {
            status: 'Add Asset',
            text: 'Please add the collateral asset to the Collateral Vault.'
        };
    }
};
const getSecuringCollateralActions = (details) => {
    if (details.actions.includes('deliver-additional-collateral')) {
        return {
            status: 'Top up Account',
            text: `Please ensure your account has at least ${formatCurrency(details.terms.collateralAmount, {
                decimalPlaces: 4,
                symbol: ` ${details.terms.collateralCurrency} `,
                short: true
            })} available.`
        };
    }
};
const getDisbursingLoanActions = (details) => {
    if (details.actions.includes('deliver-additional-lending-funds')) {
        return {
            status: 'Top up Account',
            text: `Please ensure your account has at least ${formatCurrency(details.terms.amount, {
                decimalPlaces: 4,
                symbol: ` ${details.terms.loanCurrency} `,
                short: true
            })} available.`
        };
    }
    if (details.actions.includes('sign-lending-funds-disbursal')) {
        return {
            status: 'Sign Transaction',
            text: 'Please sign the transaction.'
        };
    }
    if (details.actions.includes('treasury-approve-lending-funds-disbursal')) {
        return {
            status: 'Contact us',
            text: 'The support of your Relationship Manager is required.'
        };
    }
};
const getRepayingLoanActions = (details) => {
    if (details.actions.includes('deliver-additional-repayment-funds')) {
        return {
            status: 'Top up Account',
            text: `Please ensure your account has at least ${formatCurrency(details.terms.amount, {
                decimalPlaces: 4,
                symbol: ` ${details.terms.loanCurrency} `,
                short: true
            })} available.`
        };
    }
    if (details.actions.includes('sign-lending-funds-return')) {
        return {
            status: 'Sign Transaction',
            text: 'Please sign the transaction.'
        };
    }
    if (details.actions.includes('treasury-approve-lending-funds-return')) {
        return {
            status: 'Contact us',
            text: 'The support of your Relationship Manager is required.'
        };
    }
};
const getClaimingCollateralActions = (details) => {
    if (details.actions.includes('notice-of-exclusive-control-required') && !details.isBorrower) {
        return {
            status: 'NEC Required',
            text: 'Notice of exclusive control must be provided to Copper.'
        };
    }
    if (details.actions.includes('sign-claiming-collateral') && details.isBorrower) {
        return {
            status: 'Sign Transaction',
            text: 'Please sign the transaction.'
        };
    }
    if (details.actions.includes('treasury-approve-claiming-collateral')) {
        return {
            status: 'Contact us',
            text: 'The support of your Relationship Manager is required.'
        };
    }
};
function hasActions(actions) {
    return actions.length !== 0;
}
export const getLoanStatusAsObject = (details) => {
    const { isBorrower, loanStatus, actions, closedReason } = details;
    switch (loanStatus) {
        case 'created':
            return {
                status: 'Created',
                color: 'base'
            };
        case 'awaiting-configuration':
            const awaitingConfigurationActions = getAwaitingConfigurationActions(details);
            return {
                status: 'Awaiting Configuration',
                color: 'oxide',
                secondColor: awaitingConfigurationActions?.status ? 'orange' : undefined,
                text: awaitingConfigurationActions?.status,
                lenderMessage: awaitingConfigurationActions?.text || 'Waiting for borrower.',
                borrowerMessage: awaitingConfigurationActions?.text || 'Waiting for lender.',
                alertType: awaitingConfigurationActions?.text ? 'warning' : 'info'
            };
        case 'configured':
            return {
                status: 'Scheduled',
                color: 'base'
            };
        case 'securing-collateral':
            const securingCollateralActions = getSecuringCollateralActions(details);
            return {
                status: 'Securing Collateral',
                color: 'oxide',
                secondColor: hasActions(actions) && isBorrower ? 'orange' : undefined,
                text: isBorrower ? securingCollateralActions?.status : '',
                lenderMessage: hasActions(actions) ? 'Waiting for borrower' : '',
                borrowerMessage: securingCollateralActions?.text,
                alertType: hasActions(actions) && isBorrower ? 'warning' : 'info'
            };
        case 'disbursing-loan':
            const disbursingLoanActions = getDisbursingLoanActions(details);
            return {
                status: 'Disbursing Loan',
                color: 'oxide',
                secondColor: hasActions(actions) && !isBorrower ? 'orange' : undefined,
                text: !isBorrower ? disbursingLoanActions?.status : '',
                lenderMessage: disbursingLoanActions?.text,
                borrowerMessage: hasActions(actions) ? 'Waiting for lender' : '',
                alertType: hasActions(actions) && !isBorrower ? 'warning' : 'info'
            };
        case 'open':
            return {
                status: 'Open',
                color: 'base'
            };
        case 'margin-call':
            const marginCallActions = getSecuringCollateralActions(details);
            return {
                status: 'Margin Call',
                color: 'oxide',
                secondColor: hasActions(actions) && isBorrower ? 'orange' : undefined,
                text: isBorrower ? marginCallActions?.status : '',
                lenderMessage: hasActions(actions) ? 'Waiting for borrower' : '',
                borrowerMessage: marginCallActions?.text,
                alertType: hasActions(actions) && isBorrower ? 'warning' : 'info'
            };
        case 'rebalancing':
            return {
                status: 'Rebalancing',
                color: 'oxide'
            };
        case 'releasing-collateral':
            return {
                status: 'Releasing Collateral',
                color: 'oxide'
            };
        case 'repaying-loan':
            const repayingLoanActions = getRepayingLoanActions(details);
            return {
                status: 'Repaying Loan',
                color: 'oxide',
                secondColor: hasActions(actions) && isBorrower ? 'orange' : undefined,
                text: isBorrower ? repayingLoanActions?.status : '',
                lenderMessage: hasActions(actions) ? 'Waiting for borrower' : '',
                borrowerMessage: repayingLoanActions?.text,
                alertType: hasActions(actions) && isBorrower ? 'warning' : 'info'
            };
        case 'defaulting':
            const claimingCollateralActions = getClaimingCollateralActions(details);
            return {
                status: 'Defaulting',
                color: 'red',
                secondColor: claimingCollateralActions?.status ? 'orange' : undefined,
                text: claimingCollateralActions?.status,
                lenderMessage: claimingCollateralActions?.text,
                borrowerMessage: claimingCollateralActions?.text,
                alertType: claimingCollateralActions?.text ? 'warning' : 'info'
            };
        case 'closed':
            return getLoanClosedReasonAsObject(closedReason);
        case 'errored':
            return {
                status: 'Error',
                color: 'red'
            };
        case 'cancelling':
            return {
                status: 'Cancelling',
                color: 'oxide'
            };
        case 'writing-off':
            return {
                status: 'Writing Off',
                color: 'oxide'
            };
        default:
            return {
                status: loanStatus,
                color: 'base'
            };
    }
};
export const isNeedsAttention = (actions, isBorrower) => {
    if (isBorrower) {
        return (intersection(actions, [
            'assign-borrower-portfolio',
            'collateral-asset-activation',
            'deliver-additional-repayment-funds',
            'sign-lending-funds-return',
            'deliver-additional-collateral',
            'sign-claiming-collateral',
            'treasury-approve-lending-funds-return',
            'treasury-approve-claiming-collateral'
        ]).length > 0);
    }
    return (intersection(actions, [
        'assign-lender-portfolio',
        'deliver-additional-lending-funds',
        'release-funds-approval',
        'sign-lending-funds-disbursal',
        'sign-claiming-collateral',
        'treasury-approve-lending-funds-disbursal',
        'treasury-approve-claiming-collateral',
        'notice-of-exclusive-control-require'
    ]).length > 0);
};
export const isBorrowerOrganization = (loan, organization) => organization?.organizationId === loan.borrowerOrganizationId;
export const hasAccountSelectionActions = (actions, isBorrower) => {
    if (isBorrower) {
        return actions.includes('assign-borrower-portfolio');
    }
    return actions.includes('assign-lender-portfolio');
};
export const hasSignTransactionActions = (actions, isBorrower) => {
    if (isBorrower) {
        return (intersection(actions, ['sign-claiming-collateral', 'sign-lending-funds-return']).length > 0);
    }
    return intersection(actions, ['sign-lending-funds-disbursal']).length > 0;
};
export const hasFundsActions = (actions, isBorrower) => {
    if (isBorrower) {
        return (intersection(actions, ['deliver-additional-collateral', 'deliver-additional-repayment-funds'])
            .length > 0);
    }
    return intersection(actions, ['deliver-additional-lending-funds']).length > 0;
};
export const getLoanLtvColor = (loan) => {
    if (loan.ltvPercentage === undefined || loan.ltvPercentage === null) {
        return 'base';
    }
    const ltvPercentage = Number(loan.ltvPercentage);
    if (ltvPercentage <= 0) {
        return 'base';
    }
    if (loan.terms.rebalancePercentage && ltvPercentage <= Number(loan.terms.rebalancePercentage)) {
        return 'oxide';
    }
    else if (loan.terms.marginCallPercentage &&
        ltvPercentage <= Number(loan.terms.marginCallPercentage)) {
        return 'green';
    }
    else if (loan.terms.defaultPercentage &&
        ltvPercentage <= Number(loan.terms.defaultPercentage)) {
        return 'orange';
    }
    else if (loan.terms.defaultPercentage &&
        ltvPercentage >= Number(loan.terms.defaultPercentage)) {
        return 'red';
    }
    return 'base';
};
export const getLoanAllocationStatusObject = (loanAllocation) => {
    const { status, _embedded } = loanAllocation;
    switch (status) {
        case 'new':
        case 'order-created':
        case 'requiring-manual-intervention':
            return {
                status: 'Processing',
                color: 'orange'
            };
        case 'failed':
            return {
                status: 'Failed',
                color: 'red'
            };
        case 'completed':
            return {
                status: 'Completed',
                color: 'base'
            };
        case 'awaiting-funds':
            return {
                status: 'Awaiting funds',
                color: 'orange'
            };
        case 'awaiting-signature':
            return {
                status: 'Awaiting signature',
                color: _embedded?.isSignable ? 'oxide' : 'orange'
            };
        case 'awaiting-acceptance':
            return {
                status: 'Awaiting acceptance',
                color: _embedded?.isAcceptable ? 'oxide' : 'orange'
            };
        default:
            return {
                status: status?.split('-').join(' '),
                color: 'base'
            };
    }
};
export const sortLoanAllocationByCreatedAt = (transactionA, transactionB) => Number(transactionB?.createdAt ?? 0) - Number(transactionA?.createdAt ?? 0);
export const countProcessingTransactions = (loan) => {
    const { loanAllocations } = loan?._embedded || {};
    return loanAllocations?.length && loanAllocations?.length > 0
        ? loanAllocations.reduce((inProgress, allocation) => {
            if (['new', 'order-created', 'requiring-manual-intervention'].includes(allocation.status)) {
                inProgress++;
            }
            return inProgress;
        }, 0)
        : 0;
};
export const buildLoanTransactionLink = (loanAllocation, organizationId, isLenderOrganization, isAdmin) => {
    const pathname = `${isAdmin ? `/admin/organizations/${organizationId}` : ''}/accounts/all/transactions`;
    const { allocationType, orderId, counterpartyOrderId } = loanAllocation;
    const searchPrefix = `orderId=`;
    const orderIdLink = { pathname, search: `${searchPrefix}${orderId}`, text: orderId };
    const counterpartyOrderIdLink = {
        pathname,
        search: `${searchPrefix}${counterpartyOrderId}`,
        text: counterpartyOrderId
    };
    if (allocationType === 'seize-collateral' && !isLenderOrganization) {
        return {
            pathname,
            search: `${searchPrefix}${counterpartyOrderId}`,
            text: ''
        };
    }
    if (['disburse', 'rebalance', 'release-collateral', 'top-up'].includes(allocationType)) {
        return isLenderOrganization ? orderIdLink : counterpartyOrderIdLink;
    }
    return isLenderOrganization ? counterpartyOrderIdLink : orderIdLink;
};
export const isOpenLendingLoan = (loan) => {
    return ![
        'awaiting-acceptance',
        'awaiting-collateral',
        'defaulted',
        'cancelled',
        'errored',
        'repaid'
    ].includes(loan.status);
};
export const getLoanCounterpartyBorrowersIdsForLendingTableView = (loan) => {
    switch (loan.loanType) {
        case 'bilateral-lending-title-transfer-collateralised-loan':
            return [loan.borrowerCounterpartyId];
        case 'agency-lending-parent-loan':
            return loan._embedded?.borrowersCounterpartyIds;
        default:
            return [loan.borrowerCounterpartyId];
    }
};
export const isCollateralisedLoan = (loan) => [
    'bilateral-lending-title-transfer-collateralised-loan',
    'agency-lending-collateralised-child-loan'
].includes(loan.loanType);
export const isCollateralPortfolioRequired = ({ loan, repaymentAmount, organizationId }) => {
    const { amount: loanAmount, borrowerOrganizationId } = loan;
    const isBorrower = borrowerOrganizationId === organizationId;
    return isBorrower && BigNumber(loanAmount).eq(repaymentAmount ?? 0) && isCollateralisedLoan(loan);
};
