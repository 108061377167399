import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { NonCustodialWalletBanner } from '@copper/components/Banners/NonCustodialWalletBanner';
import { SystemAnnouncementBanner } from 'shared/components/Banners/SystemAnnouncementBanner';
import { OrganizationSwitch } from 'shared/components/OrganizationSwitch';
import { InvoicesBanner } from '@copper/client/components/Banners/Invoices';
import { BlockedOrganizationBanner, Navigation } from '@copper/components';
import { useCheckPermissionsContext } from '@copper/contexts/checkPermissions';
import { useLayoutState } from '@copper/contexts/layout';
import { selectCounterpartiesWithPendingRequest } from '@copper/entities/counterparties/counterparties-selector';
import { selectLegalEntities } from '@copper/entities/legalEntities/legalEntities-selector';
import { selectCollateralManagementLendingEnabled, selectCurrentOrganization, selectOrganizationInfo } from '@copper/entities/organizations/organizations-selector';
import { Badge, IconHome, IconLoan, IconLogo, IconNetwork, IconSettings } from '@copper/ui-kit';
import s from './Header.module.css';
const Header = () => {
    const { checkPermissions } = useCheckPermissionsContext();
    const { loadingLayout } = useLayoutState();
    const currentOrganization = useSelector(selectCurrentOrganization);
    const counterpartiesWithPendingConnections = useSelector(selectCounterpartiesWithPendingRequest);
    const { copperLegalEntityId } = useSelector(selectOrganizationInfo);
    const legalEntities = useSelector(selectLegalEntities);
    const copperLegalEntity = legalEntities[copperLegalEntityId ?? '']?.name ?? '';
    const isLendingEnabled = useSelector(selectCollateralManagementLendingEnabled);
    const incomingRequests = useMemo(() => counterpartiesWithPendingConnections.filter((counterparty) => counterparty.counterpartyId !== currentOrganization.counterparty?.counterpartyId &&
        counterparty._embedded?.counterpartyConnection?.receiverCounterpartyId ===
            currentOrganization.counterparty?.counterpartyId), [counterpartiesWithPendingConnections, currentOrganization]);
    const navItems = useMemo(() => {
        const items = [
            {
                label: 'Home',
                to: '/accounts/all',
                Icon: IconHome,
                activeWhenUrlStartWith: '/accounts'
            }
        ];
        if (checkPermissions(['find-counterparties']) &&
            currentOrganization?.organizationType !== 'oxygen') {
            items.push({
                label: 'Network',
                to: '/network',
                Icon: IconNetwork,
                rightSection: !!incomingRequests.length && _jsx(Badge, { content: incomingRequests.length })
            });
        }
        if (checkPermissions(['view-loans']) && isLendingEnabled) {
            items.push({
                label: 'Loans',
                to: '/loans',
                Icon: IconLoan
            });
        }
        items.push({
            label: 'Settings',
            to: '/settings',
            Icon: IconSettings
        });
        return items;
    }, [checkPermissions, isLendingEnabled, incomingRequests]);
    return (_jsx(_Fragment, { children: _jsxs("header", { className: s.container, children: [_jsx(BlockedOrganizationBanner, { text: "This organisation is blocked. You can only view your balances,\n          all other activities are restricted. Please contact us for further actions." }), _jsx(SystemAnnouncementBanner, {}), _jsx(NonCustodialWalletBanner, {}), _jsxs("div", { className: s.topHeader, children: [_jsx("div", { className: s.logoContainer, children: _jsx(NavLink, { to: "accounts/all", className: s.logo, children: _jsx(IconLogo, { width: 32, height: 32, viewBox: "0 0 32 32" }) }) }), _jsx("div", { className: s.navContainer, children: _jsx(Navigation, { items: navItems, showSkeleton: loadingLayout }) }), _jsx("div", { className: s.entityContainer, children: copperLegalEntity })] }), _jsx(InvoicesBanner, {}), _jsx(OrganizationSwitch, {})] }) }));
};
export { Header };
